@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gradient-to-br from-gray-50 to-gray-100 text-gray-900 min-h-screen antialiased;
  }

  * {
    @apply transition-all duration-200 ease-in-out;
  }
}

@layer components {
  .input-field {
    @apply w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent bg-white shadow-sm;
  }
  
  .btn-primary {
    @apply px-6 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 shadow-sm hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed font-medium;
  }

  .btn-secondary {
    @apply px-6 py-2 bg-white text-primary-600 border border-primary-600 rounded-lg hover:bg-primary-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 shadow-sm hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed font-medium;
  }

  .link {
    @apply text-primary-600 hover:text-primary-700 font-medium;
  }

  .card {
    @apply bg-white rounded-xl shadow-soft p-6 animate-fade-in;
  }

  .page-container {
    @apply max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8 animate-fade-in;
  }

  .section-title {
    @apply text-2xl font-bold text-gray-900 mb-6;
  }

  .form-group {
    @apply space-y-2 animate-slide-up;
  }

  .label {
    @apply block text-sm font-medium text-gray-700;
  }

  .error-text {
    @apply text-red-600 text-sm mt-1;
  }

  .success-text {
    @apply text-green-600 text-sm mt-1;
  }
}
